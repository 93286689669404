/* bottomButtons block */
.bottomButtons {
  z-index: 10;
  position: fixed;
  bottom: 0px;
  padding: 20px;
  background: #ffffff;
  width: 100%;
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  margin-left: -16px;
}

.ant-modal-content .bottomButtons {
  border-top: 0px;
  position: inherit;
}

.bottomButtonsExtra {
  position: fixed;
  right: 10px;
  bottom: 20px;
}

.Color-dark {
  .bottomButtons {
    background: #161616 !important;
    border-top: 1px solid #3c3c3c !important;
    border-left: 1px solid transparent !important;
  }
}
