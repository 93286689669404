body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

svg {
  vertical-align: baseline;
}

/* bottomButtons block */
.bottomButtons {
  z-index: 10;
  position: fixed;
  bottom: 0px;
  padding: 20px;
  background: #ffffff;
  width: 100%;
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  margin-left: -16px;
}

.ant-modal-content .bottomButtons {
  border-top: 0px;
  position: inherit;
}

/* react-jsonschema-form customizations */
.rjsf {
  padding-bottom: 30px;
}

/* react-jsonschema-form class allow to put 2 input in one row */
.rjsf-r2 .row {
  display: block;
  width: calc(50% - 5px);
  float: left;
  margin-right: 20px;
}

/* react-jsonschema-form class allow to put 3 input in one row */
.rjsf-r3 .row {
  display: block;
  width: calc(33% - 5px);
  float: left;
  margin-right: 20px;
}

/* react-jsonschema-form class allow to put 4 input in one row */
.rjsf-r4 .row {
  display: block;
  width: calc(25% - 5px);
  float: left;
  margin-right: 20px;
}

/* YAML PostProcessing configuration */

/* .YamlOneRow:hover {
  background-color: #CCCCCC;
} */

.rjsf .field-array-of-object .array-item-list {
  margin-left: 0px !important;
}

.YamlSpecsHolder {
  min-width: 600px;
  width: 600px;
  overflow: auto;
}

.ant-btn-submit {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #ffffff;
}

a:hover {
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}

.RemoveProjectFileIcon {
  color: #b1b1b1;
}

.RemoveProjectFileIcon:hover {
  color: #e10000;
}

.ant-alert-action {
  padding-left: 20px;
}

/* new layout styles */
.ant-alert-info {
  background-color: #f0fffb;
  border-color: #bfe8e2;
}

.ant-alert-error {
  background-color: #fcf8f8;
  border: 1px solid #ffccc79e;
}

.ant-btn-submit {
  background-color: #00a58e;
  border-color: #00a58e;
  color: #ffffff;
}

.ant-checkbox-inner {
  background-color: #00a58e00 !important;
  border-color: #00a58e !important;
}

.ant-checkbox-checked {
  background-color: #00a58e !important;
  border-color: #00a58e !important;
}

.ant-alert-info .ant-alert-icon {
  color: #00a58e;
}

a {
  color: #00a58e;
}

a:hover {
  color: #00766b;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #bfe8e24d;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #00a58e;
}

.ant-input:focus,
.ant-input:hover,
.ant-input-affix-wrapper:hover {
  border-color: #00a58e !important;
}

.ant-btn-primary {
  background-color: #00a58e;
  border-color: #00a58e;
  color: #ffffff;
}

.ant-result-info .ant-result-icon > .anticon {
  color: #00a58e;
}

._CustomFieldTemplate_children,
.formRowDefaultSpace {
  padding-bottom: 10px;
}

.ant-layout .ant-layout-sider {
  background: #ffffff;
  color: #000000;
}

.ant-layout .ant-layout-sider-trigger {
  background: #ffffff;
  color: #000000;
}

/* TO MAKE JSON FORM'S LABEL "BOLD" IN UI */
.ant-form-item-required {
  font-weight: 500;
}

/**
  * Monaco Editor
  Fix error related
  https://stackoverflow.com/a/71876526/7835270

  Uncaught runtime errors:
    ERROR
    ResizeObserver loop completed with undelivered notifications.
*/
.monaco-editor {
  position: absolute !important;
}

/**
  * For Service Variables tab
  https://localhost.nanoheal.work/#/project/19/settings/services/presto/variables
  */
.ServiceVariablesFullValues .ServiceVariableValue {
  word-break: break-all;
  padding: 5px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

/**
  * For Service Variables tab
  https://localhost.nanoheal.work/#/project/19/settings/services/presto/variables
  */
.ServiceVariablesShortValues .ServiceVariableValue {
  width: calc(100vw - 800px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/**
* For Service Variables tab (ProTable small border)
*/
.ant-pro-page-container-children-container-no-header {
  padding: 0px;
}

/* DARK MODE COLORS */
.Color-dark .ant-form-item-label label, .Color-dark .label, .Color-dark .root_colorTextBase {
  color: #ffffff !important;
}

.Color-dark .ant-input-affix-wrapper-focused ,.Color-dark .ant-input-affix-wrapper:hover {
  border-color: #9f9191 !important;
}

.Color-dark .ant-btn-submit {
  color: #000000 !important;
}

.Color-dark .ant-layout-sider-trigger {
  background: #161616 !important;
  color: #ffffff !important;
}

.Color-dark .ant-layout-has-sider {
  border: #000000 !important;
}

.Color-dark .css-e393sm, .Color-dark .css-fw7ao3 {
  color: #000000 !important;
}

.Color-dark .ant-collapse-header {
  color: #ffffff !important;
  background-color: #2f2828  !important;
}

.Color-dark .ant-collapse-content-box {
  background-color: #2f2828 !important;
}

.Color-dark .ant-alert-message {
  color: #ffffff !important;
}


.Color-dark .ant-form-item-extra {
  color: #ffffff !important;
}

.Color-dark .breadcrumbText {
  font-weight: bold !important;
}

.Color-dark .treeDarkA {
  background-color: #000000 !important;
  border-right: 1px solid #ffffff !important;
}

.Color-dark .treeDarkB {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.Color-dark .view-lines, .Color-dark .monaco-mouse-cursor-text, .Color-dark .margin-view-overlays, .Color-dark .decorationsOverviewRuler {
  background-color: #000000 !important;
}

.Color-dark .minimap-decorations-layer {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.Color-dark .mtk22, .Color-dark .mtk5 {
  font-weight: bold !important;
}
