.deploymentAge {
  font-size: 14px !important;
  color: #515759;
}

.settingIcon {
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
}
.settingIcon:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.awsIcon {
  width: 61.99px !important;
  height: 35px !important;
}

.copyDivTitle {
  font-weight: bold !important;
  font-size: 18px !important;
  color: #000000 !important;
}

.copyDiv {
  background: #f8f8f8;
  border: 1px solid #d8d8d8;

  padding-left: 14px;
  padding-top: 12px;
  padding-right: 14px;
  border-radius: 5px;

  .copyDivItem {
    input {
      background: #f8f8f8 !important;
      border: 0 !important;
      font-size: 14px !important;
      color: #515759 !important;
    }
  }
}

.historyIcon {
  width: 20px !important;
  height: 20px !important;
}

.ant-collapse-borderless {
  background-color: white !important;
}

.ant-collapse {
  background-color: white !important;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0 !important;
}

.detailsCards {
  background: #f8f8f8 !important;
  border-radius: 5px !important;
  border: 1px solid #d8d8d8 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  .detailsCardsName {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
  }

  .deploymentAge {
    font-size: 14px !important;
    color: #515759;
  }
}
