.welcomeWord {
  font-weight: 600;
  font-size: 16px;
  color: #98989e !important;
}

.title {
  font-weight: bold;
  font-size: 26px;
  color: #212429;
}

.inputFieldTitle {
  font-size: 12px;
  color: #212429;
  font-weight: 600;
}

.inputField {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 5px;
}

.reset {
  font-size: 12px;
  font-weight: bold;
}

.forgotPasswordWord {
  font-size: 12px;
  color: #98989e !important;
}
